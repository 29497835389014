.App {
  text-align: center;
  height: 100%;
  width: 100%;
  max-width: 800px;
  padding: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

@media (min-width: 220px) {
  .App {
    max-width: 200px;
  }
}

@media (min-width: 320px) {
  .App {
    max-width: 300px;
  }
}

@media (min-width: 420px) {
  .App {
    max-width: 400px;
  }
}

@media (min-width: 640px) {
  .App {
    max-width: 600px;
  }
}

@media (min-width: 768px) {
  .App {
    max-width: 640px;
  }
}

@media (min-width: 1024px) {
  .App {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .App {
    max-width: 800px;
  }
}

.central-column {
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  text-align: left;
}

.description-text {
  margin-bottom: 1rem;
}

.top-space {
  margin-top: 1rem;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
